@import '../config.scss';

.chain-grids-cols{
	font-size: 16px;
}
.chain-grids-item{
	display: flex;
	padding: 15px;
	.name,
	.value{
		display: block;
	}
	.name{
		color: $gray;
		width: 16rem;
	}
	.value{
		color: $normal;
	}
	.code{
		width: calc(100% - 16rem);
		letter-spacing: 0px;
		background: #2f1d0c;
		border-radius: 5px;
		padding: 15px;
		box-sizing: border-box;
		position: relative;
		.value {
			color: $hl;
		}
		pre.value{
			line-height: 1.3;
		}
		.chain-copy {
			position: absolute;
			right: 15px;
			top: 15px;
		}
		.copied {
			color: #fff;
			position: absolute;
			right: 35px;
			top: 15px;
			font-size: 12px;
		}
	}
}
.chain-icon-fail,
.chain-icon-success {
	background: rgba(80, 80, 10, 1) url(./icons/ok.png) no-repeat 10px 3px;
	background-size: auto 20px;
	color: rgba(215, 201, 170, 1);
	border-radius: 4px;
	padding: 5px 10px 6px 36px;
	line-height: 1;
}
.chain-icon-fail {
	background: #732d17 url(./icons/fail.png) no-repeat 10px 6px;
	background-size: auto 20px;
	color: #ce9786;
}
.chain-grids-swicth{
	background: rgba(26, 15, 5, 1);
	border-radius: 5px;
	margin-right: 15px;
	padding: 8px 15px;
	position: relative;
	cursor: pointer;

	.chain-swicth{
		position: absolute;
		right: 16px;
		top: 18px;
		font-size: 0;
		border:5px solid RGBA(207, 136, 0, 1);
		border-color: RGBA(207, 136, 0, 1) RGBA(207, 136, 0, 1) transparent transparent;
		transform: rotate(-45deg);
	}
}
.chain-grids-cols-close {
	.code {
		height: 140px;
		overflow: hidden;
		border-bottom: 20px solid rgba(26, 15, 5, 1);
	}
	.chain-swicth{
		top: 13px;
		transform: rotate(135deg);
	}
}
