@import '../config.scss';

.chain-seasons-new{
	display: flex;
}
.chain-season-mini:nth-child(1){

}
.chain-season-mini:nth-child(2){
	background-image: url(./assets/season2.png);
	margin-left: 20px;
}
.chain-season-mini{
	display: flex;
	vertical-align: top;
	background: #4b2d09 url(./assets/season1.png) no-repeat 0 bottom;
	background-size: 70% auto;
	border-radius: 15px;
	width: calc(50% - 50px);
	padding: 20px;
	height: 140px;
	color: #Fff;
	.chain-season-mini-left{
		width: 40%;
		margin-right: 30px;
	}
	.chain-season-mini-right{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		text-align: center;
	}
	.chain-season-mini-right-simple{

	}
	.title{
		color: $gray;
		font-size: 14px;
		line-height: 2;
		background: url(./assets/arr.png) no-repeat 2px 0;
		background-size: 20px auto;
		padding:5px 0 5px 30px;
	}
	.chain-season-btns{
		margin: 20px 0;
	}
	.chain-btn{
		zoom: 0.8;
	}
	.chain-btn+.chain-btn{
		margin-left: 20px;
	}
}
.chain-season-pool {
	display: flex;
	width: 100px;
	background: rgba(0,0,0,0.2);
	padding: 5px;
	border-radius: 5px;
	.chain-icon-reward{
		width: 36px;
		height: 36px;
		margin-right: 10px;
	}
	font-size: 12px;
	.name{
		display: block;
		color: rgba(84, 255, 0, 1);
		line-height: 1.6;
	}
	.total{}
}
.chain-season-time {
	.time{
		font-size: 12px;
		color: $hl;
	}
}

.chain-season-signups {
	background: #df8211;
	color: #fff;
	font-size: 12px;
	margin:10px 20px 0;
	padding: 3px 20px;
	text-align: center;
	position: relative;
}
.chain-season-signups::before,
.chain-season-signups::after{
    content: ' ';
    display: block;
    width: 0;
    height:0;
    border: 14px solid #3A260E;
    border-color: transparent transparent transparent #4b2d09;
    position: absolute;
    top: -3px;
    transform: scale(0.6, 1);
}
.chain-season-signups::before{
    left: -7px;
}
.chain-season-signups::after{
    transform: scale(0.6, 1) rotate(180deg);
    right:-7px;
}


.chain-season-list{

}
.chain-season-his-item-border{
	padding: 20px;
	position: relative;
}
.chain-season-his-item-border:nth-child(2){
	margin-left: 20px;
}
.chain-data-modal-close{
	position: absolute;
	right: -80px;
	top: 40px;
	background: #764F20;
	height: 2px;
	width: 80px;
	font-size: 0;
}
.chain-data-modal-close::after{
	content: '×';
	font-size: 20px;
	display: block;
	width: 24px;
	height: 24px;
	color: rgba(231, 214, 178, 1);
	border: 2px solid rgba(231, 214, 178, 1);
	border-radius: 50%;
	position: absolute;
	right: -28px;
	top: -13px;
	text-align: center;
	line-height: 21px;
	cursor: pointer;
}
.chain-season-his-item{
	display: inline-block;
	width: calc(50% - 50px);
	vertical-align: top;
	background: #4b2d09 url(./assets/barbarian.png) no-repeat 0 bottom;
	background-size: 40% auto;
	border-radius: 15px;
	color: $normal;
	.chain-season-his-head{
		display: flex;
		justify-content: space-between;
	}
	.chain-season-victory{
		color: rgba(175, 160, 139, 1);
		margin: 10px 0 5px;
		line-height: 1.8;
		img {
			height: 26px;
			vertical-align: bottom;
		}
	}
	.chain-icon-reward{
		margin-right: 10px;
	}
	.chain-season-rank-head{
		padding-bottom: 5px;
	}
	.chain-season-rank-more{
		text-align: center;
		background: rgba(0,0,0,0.3);
		border-radius: 5px;
		padding: 5px;
		margin: 0 auto;
		width: 5.5rem;
		cursor: pointer;
	}
	.chain-season-rank-more::after{
		content: '>>';
		color: $hl;
		margin-left: 5px;
		font-family: unset;
	}
	.App-game-table-rank{
		min-height: 180px;
	}
}