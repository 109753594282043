@import '../config.scss';

.invite-body {
	background: url(./assets/banner.png) no-repeat center 0;
	background-size: 100% auto;
}

.invite-slogon{
	color: $hl;
	font-size: 2rem;
	line-height: 100px;
	text-align: right;
	padding-right: 14rem;
}
.invite-link{
	background: #4b2d09 url(./assets/barbarian.png) no-repeat 0 bottom;
	background-size:auto 100%;
	border-radius: 15px;
	padding: 20px;
	box-sizing: border-box;
	margin-bottom: 50px;
	h1{
		color: $hl;
		font-size: 1rem;
	}
	.chain-content-wallet{
		position: relative;
		background: rgba(0, 0, 0, 0.5);
		display: inline-block;
		margin: 10px 10px 10px 0;
		padding: 8px 100px 8px 10px;
		border-radius: 5px;
		.chain-copy{
			right: 10px;
			top: 0px;
			cursor: pointer;
		}
		.copied{
			right: 0px;
			top: 9px;
		}
	}
}
.invite-link-warning{
	color: $warning;
}

.invite-rank-table{
	background: #4b2d09;
	border-radius: 15px;
	padding: 20px;
}

.invite-steps{
	margin-top: 50px;
	padding-bottom: 100px;
	color: $gray;
	text-align: center;
	position: relative;
	h1{
		font-weight: 400;
		font-size: 1.5rem;
	}
}
.invite-steps::before,
.invite-steps::after{
	content: '…………………………………………';
	position: absolute;
	top: 92px;
	left: 300px;
}
.invite-steps::after{
	left: 660px;
}
.invite-step1{
	background: url(./assets/step1.png) no-repeat center 0;
}
.invite-step2{
	background: url(./assets/step2.png) no-repeat center 0;
}
.invite-step3{
	background: url(./assets/step3.png) no-repeat center 0;
}
.invite-step1,
.invite-step2,
.invite-step3{
	display: inline-block;
	width: 20rem;
	font-size: .9rem;
	margin-top: 40px;
	padding-top: 5rem;
	background-size: auto 4.5rem;
}
.invite-step2{
	margin:0 40px;
}