@import '../config.scss';

.chain-profile-setting {
	background: #4c2f0a;
	border-radius: 10px;
	padding: 30px 50px;
	margin: 0 20px;
	.avatar{
		border-radius: 10px;
		margin: 0 auto 20px;
		display: block;
		height: 80px;
		padding: 5px;
		background: rgba(10, 10, 10, 0.44);
	}
}
.chain-profile-setting-item{
	padding-bottom: 20px;
	label{
		display: block;
		color: #FFD013;
		line-height: 2.2rem;
		text-indent: 5px;
	}
	input{
		background: #3b240c;
		border: 1px solid #7a5232;
		border-radius: 5px;
		height: 34px;
		width: 100%;
		color: $gray;
		text-indent: 10px;
	}
	input:focus{
		border-color: #d18707;
	}
	input[readonly]{
		color: $readonly;
	}
	input[readonly]:focus{
		border-color: #7a5232;
	}
	.chain-setting-warning,
	.chain-setting-tips{
		color: $warning;
		line-height: 2;
		margin-left: 10px;
	}
	.chain-setting-tips{
		color: $gray;
	}
}
.chain-profile-verified{
	text-align: center;
	p{
		color: rgba(231, 214, 178, 1);
		margin: 30px 50px 40px;
	}
}
.chain-profile-verified-logo{
	background: #41280a url(../../public/assets/logo-s.png) no-repeat 50% 50%;
	background-size: auto 70%;
	height: 80px;
	margin:0 20px;
	position: relative;
	margin-bottom: -10px;
	border-radius: 10px 10px 0 0;
}

