@import '../config.scss';

.chain-wallets{
	display: grid;
	grid-template-columns: 51% 20% 25%;
	grid-template-rows: repeat(2, 150px);
	grid-row-gap: 20px;
	grid-column-gap: 20px;
	grid-auto-flow: column dense;
}
.chain-wallet-item{
	background: #452d12;
	border-radius: 10px;
	color: $normal;
	.total {
		.number { 
			color: $normal; 
			display: block; 
			font-size: 28px;
			font-weight: 700;
			line-height: 1;
		}
		.name { color: $hl; font-size: 14px; opacity: 0.8; }
	}
}
.chain-wallet-rose{
	background:#452d12 url(./assets/flower.png) no-repeat 100% 0;
	background-size: auto 75%;
	grid-row-start: 1;
	grid-row-end: 3; 
	padding: 20px;
	position: relative;

	.total {
		.number {
			display: inline;
			margin-right: 10px;
		}
		.value{
			color: $gray;
		}
	}
	.chain-wallet-rose-total{
		.total .number {
			font-size: 36px;
			color: $gray;
		}
		.total .name {
			color: $gray;
			font-weight: 700;
			font-size: 28px;
			opacity: 1;
		}
		.total .value{
			font-size: 20px;
			color: $hl;
		}
	}
	.chain-wallet-rose-balance{
		margin: 10px 0;
		padding-left: 15px;
		.total{
			display: inline-block;
			padding: 5px 0.8rem 5px 1.5rem;
			background: rgba(0,0,0,0.5);
			background-size: auto 80%;
			border-radius: 5px;

			img {
				height: 34px;
				position: absolute;
				margin: -7px 0 0 -2.5rem;
			}
		}
		.total .number,
		.total .name {
			color: $normal;
			font-size: 20px;
			line-height: 1;
			opacity: 1;
		}
	}

	.chain-content-wallet {
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
		background: rgba(0,0,0,0.5);
		border-radius: 10px;
		padding: 7px 5px 7px 10px;
		.address{
			display: inline-block;
			width: calc(100% - 30px);
		}
		.copied{
			margin-left: -20px;
		}
		.chain-copy{
			position: static;
			margin:2px 0 0 5px;
			vertical-align: top;
		}
		.link{
			background: url(./assets/link.png) no-repeat 100% 0;
			background-size: auto 100%;
			font-size: 0;
			display: inline-block;
			width: 16px;
			height: 16px;
			margin:5px 0;
			vertical-align: top;
		}
	}
}
.chain-wallet-nft{
	grid-row-start: 1;
	grid-row-end: 3; 
	grid-column-start: 2;
	grid-column-end: 3;
	text-align: center;
	img {
		height: 220px;
		margin: 20px 20px 10px;
	}
}
.chain-wallet-ape{
	grid-row-start: 1;
	grid-row-end: 1; 
	grid-column-start: 3;
	grid-column-end: 4;  
	display: flex;
	align-items: flex-end;
	img {
		height: 110px;
		border-radius: 10px;
		margin: 20px;
	}
	.total{
		margin-bottom: 25px;
	}
}
.chain-wallet-airose{
	grid-row-start: 2;
	grid-row-end: 2; 
}