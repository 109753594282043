.website-foot{
	padding-bottom: 20px;
	background: RGBA(93, 58, 15, 1);
}
.website-partners{
    padding-top: 2rem;
}
.website-partner-list{
    text-align: center;
    margin: 1rem 0 2rem;
}
.website-partner-list li{
    display: inline-block;
    margin: 1rem 2rem;
}
.website-partner-list li img{
    height: 3rem;
    /*background: #fff;*/
    /*border: 10px solid #fff;*/
}
.website-partner-list li span{
    font-size: 0;
}
.website-follow{
    margin: 1rem auto;    
}
.website-socails{
    text-align: center;
}
.website-socails a{
    margin: 0 0.6rem 1rem;
    font-size: 0;
    display: inline-block;
    width: 64px;
    height: 72px;
    background-image: url(./assets/socails.png);
    background-size: auto 100%;
    zoom: 0.8;
}
.s1{background-position: 0 0;}
.twitter{background-position: -73px 0;}
.discord{background-position: -148px 0;}
.telegram{background-position: -222px 0;}
.facebook{background-position: 100% 0;}

.website-copy{
    color: #af9071;
    text-align: center;
    font-size: 12px;
    padding:0 1rem 1rem;
}
.website-copy a{
    color: #b1f1ff;
}
