@import '../config.scss';

.chain-activities{
	text-align: left;
	padding-left: 10px;
}
.chain-activity-item-wrapper:nth-child(3n + 2){
	margin-left: 30px;
	margin-right: 30px;
}
.chain-activity-item-wrapper{
	display: inline-block;
	width: calc(33% - 70px);
}
.chain-activity-item{
	border-radius: 15px;
	background: rgba(213,199,169, 0);
	border: 5px solid #6E4623;
	// border-image: linear-gradient(0deg, #6E4623, #764F20) 10 10;
	box-shadow: 0px 0px 80px 0px rgba(30,12,5,0.6);
	margin-top: 30px;
	padding: 20px;
	text-align: center;

	img.banner {
		width: 60%;
	}
	.rank,
	.season,
	.period{
		display: block;
		text-align: center;
	}
	.rank{
		padding: 7px 10px 13px;
		margin-top: -20px;
		position: relative;
		background: url(./assets/rank.png) no-repeat center 0;
		background-size:auto 100%;
		color: $hl;
		line-height: 1;
		font-size: 14px;
	}
	.season{
		color: $normal;
		font-weight: 700;
		line-height: 2;
	}
	.period{
		font-size: 14px;
		color: $gray;
	}
}
.chain-activity-item-wrapper:hover{
	.chain-activity-item{
		position: relative;
		border-radius: 15px 15px 0 0;
		border-color:#764F20;
		border-bottom: none;
		background: #492e0e;
	}

	.chain-activity-item-more{
	 	display: block;
	 	position: absolute;
	 	left: -5px;
	 	width: calc(100% - 40px);
	 	z-index: 9999;
	 	padding-top: 30px;
		padding: 20px;
		border-radius: 0 0 15px 15px;
		border: 5px solid #764F20;
		border-top: none;
		background: #492e0e;
	 }
}
.chain-activity-item-more{
	display: none;
	margin-top: 10px;
	color: $gray;
	text-align: left;
	.camp {
		margin: 10px 0 0 10px;
	}
	.camp img{
		height: 30px;
		display: inline-block;
		vertical-align: bottom;
	}
	.camp .camp-our{
		display: inline-block;
		background: #3A250F;
		padding-right: 20px;
		font-size: 14px;
		line-height: 30px;
		border-radius: 5px;
		img{
			margin-left: -10px;
			margin-right: 10px;
		}
	}
	.award{
		background: #3A250F;
		border-radius: 5px;
		text-align: right;
		padding: 5px;
		margin: 5px 10px;
		font-size: 14px;
		position: relative;
	}
	.award::before{
		content: ' ';
		display: block;
		width: 36px;
		height: 36px;
		background: url(./assets/award.png) no-repeat 0 0;
		background-size: auto 100%;
		position: absolute;
		left: -5px;
		top: -2px;
	}
}
.chain-activity-item-title{
	font-weight: 400;
	text-align: left;
	font-size: 14px;
	text-indent: 5px;
	margin-top: 10px;
}
