
.chain-split{
	background: url(./assets/splitter.png) no-repeat center 0;
	background-size:100% 60%;
	height: 15px;
}
.chain-icon-reward{
	background:url(./assets/diamond.png) no-repeat 0 bottom;
	background-size:100% 100%;
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: bottom;
}

.chain-icon{
	display: inline-block;
	width:14px;
	height:12px;
	background-size: auto 100%;
}
.chain-icon-overview{
	background: url(./icons/overview.png) no-repeat center center;
	background-size: auto 100%;
}
.chain-icon-event{
	background: url(./icons/event.png) no-repeat center center;
	background-size: auto 100%;
}
.chain-copy {
	background: url(./icons/copy.png) no-repeat 0 0;
	background-size: 100% 100%;
	display: inline-block;
	width: 14px;
	height: 15px;
	font-size: 0;
}

.chain-btn,
a.chain-btn {
	background: linear-gradient(180deg, #88B500, #699500);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1), 
				0px -5px 0px 0px rgba(52,61,0,0.4), 
				0px 2px 0px 0px rgba(130,170,11,0.5);
	border-radius: 5px;
	padding: 8px 30px;
	cursor: pointer;
	color: #fff;
	text-align: center;
}
.chain-btn:hover{
	opacity: 0.8;
}
.chain-btn-blue,
a.chain-btn-blue{
	background: linear-gradient(180deg, #0088F1, #0070D9);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(255,255,255,0.4);
}
.chain-btn-disabled{
	background: linear-gradient(180deg, #000, #261909);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(0,0,0,0.2);
	color:#999;
	opacity: 0.6;
}

.icon-invite-facebook{ background: url(./icons/facebook.png); }
.icon-invite-twitter{ background: url(./icons/twitter.png); }
.icon-invite-facebook,
.icon-invite-twitter{
	display: inline-block;
	cursor: pointer;
	width: 28px;
	height: 28px;
	font-size: 0;
	background-size: 100% 100%;
	vertical-align: middle;
}