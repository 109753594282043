@import '../config.scss';

.chain-content-title{
	/*display: flex;
	justify-content: space-between;
	align-items: center;*/
	padding: 0 20px;
	h2{
		text-align: left;
		color: $gray;
		font-size: 24px;
	}
}

.chain-body {
	min-height: 300px;
	padding-top: 80px;
	background:#5d3a0f;
	.website-foot{
		background: none;
	}
}
.chain-body-map{
	background:#5d3a0f url(./assets/bg-map.png) no-repeat 0 0;
	background-size:100% auto;
}
.chain-body-season{
	background:#5d3a0f url(./assets/bg-season.png) no-repeat 0 0;
	background-size:100% auto;
}
.chain-body-footer{
	.website-partners{
		display: none;
	}
	.website-foot{
		background: none;
	}
}
.chain-head{
	padding-top: 30px;
	padding-bottom: 50px;
	img{
		height: 50px;
		cursor: pointer;
	}
	input {
		display: none;
		float:right;
		background: #39230d url(./assets/search.png) no-repeat 95% center;
		background-size: auto 60%;
		border:none;
		border-radius: 15px;
		height: 30px;
		width: 280px;
		outline: none;
		color:#503d32;
		text-indent: 20px;
	}
}

.chain-content-total{
	float:right;
	margin-top: 10px;
	background: #322213;
	border-radius: 5px;
	padding: 6px 15px;
	label{ color: #d5c8bb; margin-right:10px; }
	span{ color: #ffdd93; }
}

.chain-content-wallet{
	color: $gray;
	font-size: 14px;
	padding-left: 20px;

	.title{
		background: #3d260a;
		display: inline-block;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.6;
		color: $gray;
		padding: 5px 40px 5px 10px;
		border-radius: 5px;
	}
	.chain-copy {
		position: absolute;
		margin: 9px 0 0 -26px;
	}
	.copied {
		position: absolute;
		margin: -20px 0 0 -28px;
		color: #fff;
	}
	.user {
		display: block;
		margin:1rem 0 0 5px;
	}
}

.chain-types{
	text-align: right;
	padding: 0 10px 10px 0;
	margin-top: -40px;
	// display: none;
	label {
		color: $hl;
		margin-right: 5px;
	}
}

.chain-tabs{
	text-align: center;
	margin: 20px 10px 10px;
	.chain-icon{
		margin-right: 5px;
	}
}

.chain-login {
	position: fixed;
	left: 0;
	right: 0;
	top:0;
	bottom: 0;

	h1{
		display: inline;
		background: #000;
		color:#fff;
		padding: 20px;
		position: absolute;
		left: 50%;
		top:50%;
		margin: -20px 0 0 -150px;
		cursor: pointer;
		border-radius: 20px;
	}
}
.chain-nodata{
	text-align: center;
	line-height: 100px;
	color: rgb(207, 197, 175);
}
.chain-tips{
	text-align: center;
	font-size: 14px;
	color: rgb(207, 197, 175);
}


.website-daily-transaction,
.website-daily-totals{
	padding: 30px 20px;
	display: inline-block;
	vertical-align: top;
}
.website-daily-total-item{
	display: inline-block;
	width: calc(50% - 15px);
	padding: 15px 15px 15px 0;

	label{
		color: rgba(242, 221, 192, 1);
		display: block;
		font-weight: 400;
	}
	span{
		color: rgba(255, 162, 0, 1);
		font-size: 24px;
	}
}

