@import '../config.scss';

.chain-item{
	display: inline-block;
	width: 180px;
	margin: 10px;
	img{
		width: 100%;
	}
}
.chain-medal-item{
	display: inline-block;
	background: #5F4023;
	border: 3px solid #72542A;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	padding: 10px;
	margin: 20px 10px;
	text-align: center;
	img{
		width: 100%;
		margin-bottom: -25px;
	}
	.season{
		background: url(./assets/s.png) no-repeat 0 0;
		background-size: 100% 100%;
		display: inline-block;
		width: 48px;
		height: 48px;
		color: $hl;
		font-weight: 700;
		line-height: 48px;
		text-align: center;
	}
}